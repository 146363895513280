import styled from 'styled-components';
import React, { useMemo } from 'react';
import { useLocale } from 'hooks';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { PermissionGroups, PermissionsTypesLocalesMap } from 'constants/index';

const PermissionsTypesTableCell: React.FC<{ id: number; permissions: Permissions.Permissions }> = ({
  id,
  permissions,
}) => {
  const { getIntl } = useLocale();

  const permissionsByGroups = useMemo(
    () =>
      (Object.keys(permissions) as Permissions.PermissionsTypes[]).reduce(
        (acc: Record<PermissionGroups, any>, key) => {
          const { group, labelKey } = PermissionsTypesLocalesMap[key as Permissions.PermissionsTypes] || {};
          acc[group].total = (acc[group].total || 0) + 1;
          acc[group].enabled = (acc[group].enabled || 0) + Number(permissions[key]);
          acc[group].list = permissions[key] ? [...(acc[group].list || []), labelKey] : acc[group].list || [];
          return acc;
        },
        Object.values(PermissionGroups).reduce(
          (acc, key) => {
            acc[key] = [];
            return acc;
          },
          {} as Record<PermissionGroups, any[]>
        )
      ),
    [permissions]
  );

  return (
    <StyledCell>
      {Object.entries(permissionsByGroups).map(([key, value]) => {
        const percentage = (value.enabled / value.total) * 100;
        const Badge = (
          <StyledBadge key={id + key}>
            <BadgeText>
              {key}{' '}
              <span>
                ({value.enabled}/{value.total})
              </span>
            </BadgeText>
            <IndicatorContainer>
              <Indicator $percentage={percentage} style={{ width: `${percentage}%` }} />
            </IndicatorContainer>
          </StyledBadge>
        );
        if (!value.list.length) return Badge;
        return (
          <OverlayTrigger
            placement="auto"
            trigger={['hover', 'hover']}
            rootClose
            overlay={
              <StyledPopover id={id + key}>
                <Popover.Content>
                  {value.list.map((item: string) => (
                    <div>{getIntl(item)}</div>
                  ))}
                </Popover.Content>
              </StyledPopover>
            }
          >
            {Badge}
          </OverlayTrigger>
        );
      })}
    </StyledCell>
  );
};

const StyledCell = styled.td`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
`;

const StyledBadge = styled.span`
  background-color: ${props => props.theme.colors.purple25};
  border-radius: 8px;
  padding: 6px 8px;
  position: relative;
  font-size: 85%;
  font-weight: 500;
  white-space: nowrap;
  user-select: none;
`;

const BadgeText = styled.div`
  margin-bottom: 4px;

  > span {
    display: inline-block;
    width: 40px;
    text-align: right;
  }
`;

const IndicatorContainer = styled.div`
  background-color: ${props => props.theme.colors.grey50};
  border-radius: 5px;
  height: 4px;
  overflow: hidden;
`;

const Indicator = styled.div<{ $percentage: number }>`
  background-color: ${props => props.theme.colors.purple150};
  height: 100%;
  width: ${props => props.$percentage}%;
`;

const StyledPopover = styled(Popover)`
  border-radius: 8px;
  border: none;

  .popover-body {
    padding: 10px;
    color: ${props => props.theme.colors.grey400};
    font-size: 12px;
    white-space: nowrap;
  }

  .arrow::before {
    border: none;
  }
`;

export default PermissionsTypesTableCell;
