import queryString from 'query-string';
import { createAction } from 'redux-actions';
import { paginationSelectorFactory, portfolioIdSelector, scenarioIdSelector } from 'modules/layouts/selectors';
import { isPortfolioLoadflowEnabledSelector } from 'modules/options/selectors';
import { _keyBy } from '@utiligize/shared/utils';
import { PaginationType, AssetLifeAPI } from 'constants/index';

// ------------------------------------
// Actions
// ------------------------------------
export const fetchCablesAction: any = createAction(
  'network-loading/FETCH_CABLES',
  async ({ skipPagination, skipStoreUpdate } = { skipPagination: false, skipStoreUpdate: undefined }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<NetworkLoading.Root, 'cablesHash' | 'cablesCount'>> => {
      const state: State.Root = getState();
      const isPortfolioLoadflowEnabled = isPortfolioLoadflowEnabledSelector(state);
      if (!isPortfolioLoadflowEnabled) return Promise.resolve({ cablesCount: 0, cablesHash: {} });
      const { limit, offset, sort, column, query, filters } = paginationSelectorFactory(PaginationType.CABLES)(state);
      return AssetLifeAPI.get('load/load_cables', {
        params: {
          limit: skipPagination ? undefined : limit,
          offset: skipPagination ? 0 : offset,
          sort,
          column,
          query,
          portfolio_id: portfolioIdSelector(state),
          scenario_id: scenarioIdSelector(state),
          voltage: filters?.voltage,
          hide_solved: filters?.solvedValue,
          year: filters?.year,
          voltage_display: filters?.voltageDisplay,
          percentile: filters?.percentile,
          flex: filters?.flex,
        },
      }).then((res: any) => ({
        cablesCount: res.data.count,
        cablesHash: _keyBy(res.data.rows, (item: NetworkLoading.Cable) => item.id),
        skipStoreUpdate,
      }));
    }
);

interface GetChartDataParams {
  portfolioId: Layouts.Root['portfolioId'];
  scenarioId: Layouts.ScenarioId;
  simulationId: Layouts.Root['simulationId'];
  voltage: Layouts.Filters['voltage'];
  year: Layouts.Filters['year'];
  percentile: Layouts.Filters['percentile'];
  flex: Layouts.Filters['flex'];
}

export const getCablesHistoricalLoadingChartData = createAction(
  'network-loading/GET_CABLES_HISTORICAL_LOADING_CHART_DATA',
  ({ portfolioId, scenarioId, simulationId, voltage, year, percentile, flex }: GetChartDataParams) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Omit<Shared.ChartAPIResponse, 'series' | 'datetime_x'> | null> => {
      const state = getState();
      const isPortfolioLoadflowEnabled = isPortfolioLoadflowEnabledSelector(state);
      if (!isPortfolioLoadflowEnabled) return Promise.resolve(null);
      return AssetLifeAPI.get(
        `/load/load_cables_hist_plot?${queryString.stringify({
          portfolio_id: portfolioId,
          scenario_id: scenarioId,
          simulation_id: simulationId,
          voltage,
          year,
          percentile,
          flex,
        })}`
      ).then(res => res.data);
    }
);

export const getCablesVoltageChartData = createAction(
  'network-loading/GET_CABLES_VOLTAGE_CHART_DATA',
  ({ portfolioId, scenarioId, simulationId, voltage, year, percentile, flex }: GetChartDataParams) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Omit<Shared.ChartAPIResponse, 'series' | 'datetime_x'> | null> => {
      const state = getState();
      const isPortfolioLoadflowEnabled = isPortfolioLoadflowEnabledSelector(state);
      if (!isPortfolioLoadflowEnabled) return Promise.resolve(null);
      return AssetLifeAPI.get(
        `/load/voltage_cables_hist_plot?${queryString.stringify({
          portfolio_id: portfolioId,
          scenario_id: scenarioId,
          simulation_id: simulationId,
          voltage,
          year,
          percentile,
          flex,
        })}`
      ).then(res => res.data);
    }
);
